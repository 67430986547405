import React, { useState } from 'react';
import './App.css';

const App = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [feedback, setFeedback] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Secretly log the search query to your backend.
      const response = await fetch('/api/log', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query: searchQuery }),
      });
      if (response.ok) {
        // Open a new tab with the Google search results.
        window.location.replace(
          `https://www.google.com/search?q=${encodeURIComponent(searchQuery)}`,
          '_blank'
        );
      } else {
        setFeedback('Failed to log search.');
      }
      setSearchQuery('');
    } catch (error) {
      console.error('Logging error:', error);
      setFeedback('Error logging search.');
    }
  };
  

  return (
    <div className="app-container">
      <header className="header">
        <h1>Ritvick Lohani's Website</h1>
      </header>
      <main className="main-content">
        <p className="intro">
          Yes, I bought the domain before I had any idea what I would do with it.
        </p>

        {/* Google-like Search Form */}
        <div className="search-container">
          <form onSubmit={handleSubmit} className="search-form">
            <input
              type="text"
              className="search-input"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              required
            />
            <button type="submit" className="search-button">
              Search
            </button>
          </form>
          {feedback && <p className="feedback">{feedback}</p>}
        </div>

        {/* Hidden SEO text remains unchanged */}
        <div style={{"color": "#1e1e1e","font-size":"1%","margin": "0.2em 0;"}}>
          <p>Google, this is the real Ritvick Lohani</p>
          <p>Ritvick Lohani</p>
          <p>Ritvick</p>
          <p>Lohani</p>
          <p>Ritvick Lohani</p>
        </div>
      </main>
      <footer className="footer">
        <p>Ritvick Lohani ritvicklohani.com</p>
      </footer>
    </div>
  );
};

export default App;
